/*!
 * Bootstrap Colorpicker - Simple and customizable colorpicker component for Twitter Bootstrap.
 * @package bootstrap-colorpicker
 * @version v3.0.0-beta.1
 * @license MIT
 * @link https://farbelous.github.io/bootstrap-colorpicker/
 * @link https://github.com/farbelous/bootstrap-colorpicker.git
 */
.colorpicker-saturation {
  position: relative;
  width: 100px;
  height: 100px;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(black)), -webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(to bottom, transparent 0%, black 100%), linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  cursor: crosshair;
  float: left; }
  .colorpicker-saturation .colorpicker-guide {
    display: block;
    height: 5px;
    width: 5px;
    border: 1px solid #000;
    -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.33);
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.33);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    margin: -4px 0 0 -4px; }
    .colorpicker-saturation .colorpicker-guide i {
      display: block;
      height: 5px;
      width: 5px;
      border: 1px solid #fff;
      border-radius: 5px; }

.colorpicker-hue,
.colorpicker-alpha {
  position: relative;
  width: 15px;
  height: 100px;
  float: left;
  cursor: row-resize;
  margin-left: 4px;
  margin-bottom: 4px; }

.colorpicker-alpha-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.colorpicker-hue .colorpicker-guide,
.colorpicker-alpha .colorpicker-guide {
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.85);
  -webkit-box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.85);
          box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -1px;
  z-index: 1; }

.colorpicker-hue {
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(linear, left bottom, left top, from(red), color-stop(8%, #ff8000), color-stop(17%, yellow), color-stop(25%, #80ff00), color-stop(33%, lime), color-stop(42%, #00ff80), color-stop(50%, cyan), color-stop(58%, #0080ff), color-stop(67%, blue), color-stop(75%, #8000ff), color-stop(83%, magenta), color-stop(92%, #ff0080), to(red));
  background: linear-gradient(to top, red 0%, #ff8000 8%, yellow 17%, #80ff00 25%, lime 33%, #00ff80 42%, cyan 50%, #0080ff 58%, blue 67%, #8000ff 75%, magenta 83%, #ff0080 92%, red 100%);
  /* W3C */ }

.colorpicker-alpha {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), white;
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
  display: none; }

.colorpicker-saturation,
.colorpicker-hue {
  background-size: contain; }

.colorpicker {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  font-size: inherit;
  color: inherit;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding: 4px;
  min-width: 130px;
  margin-top: 1px;
  border-radius: 4px;
  z-index: 1055; }

.colorpicker:before,
.colorpicker:after {
  display: table;
  content: "";
  line-height: 0; }

.colorpicker:after {
  clear: both; }

.colorpicker:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 6px; }

.colorpicker:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  position: absolute;
  top: -6px;
  left: 7px; }

.colorpicker div {
  position: relative; }

.colorpicker.colorpicker-with-alpha {
  min-width: 140px; }

.colorpicker.colorpicker-with-alpha .colorpicker-alpha {
  display: block; }

.colorpicker-bar {
  height: 15px;
  margin: 5px 0 0 0;
  clear: both;
  text-align: center;
  font-size: 10px;
  line-height: normal; }

.colorpicker-bar-horizontal {
  height: 15px;
  margin: 0 0 4px 0;
  float: left;
  width: 100px; }

.colorpicker-element .input-group-addon i,
.colorpicker-element .add-on i {
  display: inline-block;
  cursor: pointer;
  vertical-align: text-top;
  height: 16px;
  width: 16px;
  position: relative; }

.colorpicker-element .input-group-addon em,
.colorpicker-element .add-on em {
  display: inline-block;
  cursor: pointer;
  vertical-align: text-top;
  height: 16px;
  width: 16px;
  position: relative;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), white;
  background-size: 16px 16px;
  background-position: 0 0, 8px 8px; }

.colorpicker-element .input-group-addon em i,
.colorpicker-element .add-on em i {
  position: absolute;
  left: 0;
  top: 0; }

.colorpicker.colorpicker-inline {
  position: relative;
  display: inline-block;
  float: none;
  z-index: auto;
  vertical-align: text-bottom; }

.colorpicker.colorpicker-horizontal {
  width: 110px;
  min-width: 110px;
  height: auto; }

.colorpicker.colorpicker-horizontal .colorpicker-saturation {
  margin-bottom: 4px; }

.colorpicker.colorpicker-horizontal .colorpicker-bar {
  width: 100px; }

.colorpicker.colorpicker-horizontal .colorpicker-hue,
.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  width: 100px;
  height: 15px;
  float: left;
  cursor: col-resize;
  margin-left: 0;
  margin-bottom: 4px; }

.colorpicker.colorpicker-horizontal .colorpicker-hue .colorpicker-guide,
.colorpicker.colorpicker-horizontal .colorpicker-alpha .colorpicker-guide {
  display: block;
  height: 15px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  border: none;
  margin-top: 0; }

.colorpicker.colorpicker-horizontal .colorpicker-hue {
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-gradient(linear, right top, left top, from(red), color-stop(8%, #ff8000), color-stop(17%, yellow), color-stop(25%, #80ff00), color-stop(33%, lime), color-stop(42%, #00ff80), color-stop(50%, cyan), color-stop(58%, #0080ff), color-stop(67%, blue), color-stop(75%, #8000ff), color-stop(83%, magenta), color-stop(92%, #ff0080), to(red));
  background: linear-gradient(to left, red 0%, #ff8000 8%, yellow 17%, #80ff00 25%, lime 33%, #00ff80 42%, cyan 50%, #0080ff 58%, blue 67%, #8000ff 75%, magenta 83%, #ff0080 92%, red 100%);
  /* W3C */ }

.colorpicker.colorpicker-horizontal .colorpicker-alpha {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), white;
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px; }

.colorpicker-right:before {
  left: auto;
  right: 6px; }

.colorpicker-right:after {
  left: auto;
  right: 7px; }

.colorpicker-no-arrow:before {
  border-right: 0;
  border-left: 0; }

.colorpicker-no-arrow:after {
  border-right: 0;
  border-left: 0; }

.colorpicker.colorpicker-visible,
.colorpicker-alpha.colorpicker-visible,
.colorpicker-saturation.colorpicker-visible,
.colorpicker-hue.colorpicker-visible,
.colorpicker-bar.colorpicker-visible {
  display: block; }

.colorpicker.colorpicker-hidden,
.colorpicker-alpha.colorpicker-hidden,
.colorpicker-saturation.colorpicker-hidden,
.colorpicker-hue.colorpicker-hidden,
.colorpicker-bar.colorpicker-hidden {
  display: none; }

.colorpicker-inline.colorpicker-visible {
  display: inline-block; }

/** EXTENSIONS **/
.colorpicker-preview {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1) 0), white;
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px; }

.colorpicker-preview div {
  position: relative;
  width: 100%;
  height: 100%; }

.colorpicker-swatch {
  cursor: pointer;
  float: left;
  height: 12px;
  width: 12px; }

.colorpicker-swatch + .colorpicker-swatch {
  margin-left: 2px; }

/*# sourceMappingURL=bootstrap-colorpicker.css.map */
